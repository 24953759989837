import { Link } from "react-router-dom";
import ContactSection from "../components/ContactSection";

export default function Contact() {
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <h2>Contact</h2>
              <ol className="breadcrumb">
                <li>
                  <Link to="/" style={{ color: "#fff" }}>
                    Home
                  </Link>
                </li>
                <li>Contact</li>
              </ol>
            </div>
          </div>{" "}
          {/* end row */}
        </div>{" "}
        {/* end container */}
      </section>
      <ContactSection />
    </>
  );
}
