import { Link } from "react-router-dom";

export default function AboutSection2() {
  return (
    <section
      className="about-section section-padding"
      style={{ marginTop: 100 }}
    >
      <div className="container">
        <div className="row">
          <div className="col col-md-6">
            <div className="about-info">
              <h3>About Us</h3>
              <p>
                From generating deal flow to analyzing private equity fund and
                venture capital performance, TIGER CREST CAPITAL INVESTMENT
                LLC.(Herein after “TIGER CREST CAPITAL INVESTMENT”) provides the
                comprehensive information on this market you need
              </p>
              <p>
                We combine our partners’ resources with those of private sector
                investors to nurture early-stage technology startups as well as
                low-middle stage, giving the potential investee’s talent the
                opportunity to make an impact on the world. We grow our
                ecosystem by encouraging more private funding or investment from
                angel investors and venture capitalists. Grow TIGER CREST
                CAPITAL INVESTMENT ’s ecosystem with us.
              </p>
              <p>
                We’ve worked alongside our global investor community to
                understand the fundamental motivations behind their investment
                strategies. With just 4% of analyzed deals presented to our
                investors, we focus on discovering the passionate founders,
                innovative companies and established funds that best fit your
                mandates.
              </p>
            </div>
            <Link to="/investmentphilosophy" className="theme-btn">
                Investment Philosophy
              </Link>
          </div>
          <div className="col col-md-6">
            <div className="video-holder">
              <img src="assets/images/services/img-2.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </section>
  );
}
