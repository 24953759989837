import { Link } from "react-router-dom";

export default function AboutSection() {
  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-md-6">
            <div className="about-info">
              <h3>Where Capital Market Meets Cutting-Edge Technology</h3>
              <p>
                We combine our partners’ resources with those of private sector
                investors to nurture early-stage technology startups as well as
                low-middle stage, giving the potential investee’s talent the
                opportunity to make an impact on the world. We grow our
                ecosystem by encouraging more private funding or investment from
                angel investors and venture capitalists.
              </p>
              {/* <ul>
                <li>
                  <h5>Stay ahead of the market</h5>
                  <p>
                    Get agenda-setting scoops on transformative deals from
                    authoritative sources across multiple industries. We believe
                    that high-quality management is critical to any successful
                    investment. We do not interfere with daily management
                    activities but aims to align its interest with the
                    management both financially and strategically.
                  </p>
                </li>
                <li>
                  <h5>Receive actionable intelligence</h5>
                  <p>
                    Monitor deal flow, spot market trends, and gain insight into
                    your competitive positioning within any region, asset class,
                    or industry vertical with flexible levels of granularity.
                    Identify potential sponsors for sell-side M&A transactions
                    or follow-on rounds. Determine the best partners,
                    co-investors, or buyers for an exit strategy and uncover
                    candidates that may be ripe for a merger, acquisition, or
                    divestiture.
                  </p>
                </li>
                <li>
                  <h5>Private investing made simple</h5>
                  <p>
                    We’ve worked alongside our global investor community to
                    understand the fundamental motivations behind their
                    investment strategies. With just 4% of analyzed deals
                    presented to our investors, we focus on discovering the
                    passionate founders, innovative companies and established
                    funds that best fit your mandates.
                  </p>
                </li>
              </ul> */}
              <Link to="/about" className="theme-btn">
                More about us
              </Link>
            </div>
          </div>
          <div className="col col-lg-5 col-lg-offset-1 col-md-6">
            <div className="video-holder">
              <img src="assets/images/about-1.jpg" alt="" style={{width:"100%"}}/>
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </section>
  );
}
