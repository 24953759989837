import { Link } from "react-router-dom";

export default function CTASection2() {
  return (
    <section className="cta-section" style={{background:"url('assets/images/testimonials/bg.jpg')"}}>
      <div className="container">
        <div className="row">
          <div className="col col-lg-8 col-sm-10">
            <div className="cta-text">
              <h3>Diversify your portfolio</h3>
              <p>
                Diversify across asset classes and cut down on research time by
                accessing unique investment offerings on one platform
              </p>
              <Link to="/contact" className="theme-btn" style={{backgroundColor:"gold",color:"#191c21"}}>Reach Out To Us</Link>
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </section>
  );
}
