import { Link } from "react-router-dom";

export default function InvestmentPhilosophy() {
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <h2>Investment Philosophy</h2>
              <ol className="breadcrumb">
                <li>
                  <Link to="/" style={{ color: "#fff" }}>
                    Home
                  </Link>
                </li>
                <li>Investment Philosophy</li>
              </ol>
            </div>
          </div>{" "}
          {/* end row */}
        </div>{" "}
        {/* end container */}
      </section>

      <section className="case-single-section section-padding">
        <div className="container">
          <div className="row content">
            <div className="col col-xs-12">
              <div className="overview-info">
                <div className="overview">
                  <h2>
                    Focused Approach to Adding Value, with a Highly Experienced
                    Team
                  </h2>
                  <p>
                    TIGER CREST CAPITAL INVESTMENT is independent and solely
                    focused on maximizing shareholder value. We are thus
                    positioned to be an objective, high quality partner to
                    management teams and owners in making major strategic
                    decisions. Our sector focus propels us to work with
                    management teams to add value in a targeted manner, based on
                    their unique needs. We seek to generate returns through
                    fundamental growth rather than financial engineering.
                  </p>
                </div>
              </div>
            </div>
            <div className="col col-xs-12">
              <div className="overview-info">
                <div className="overview">
                  <h2>
                    Access to Significant Resources For Middle Market Companies
                  </h2>
                  <p>
                    We have access to substantial resources to add value to our
                    portfolio companies, including decades of wide ranging
                    experience and a strong and deep network of relationships
                    with industry experts, financial partners, transaction
                    advisors, customers, suppliers and others. TIGER CREST
                    CAPITAL INVESTMENT actively manages its portfolio assets
                    where it works closely with management to restructure,
                    develop and drive growth of the business to maximise value
                    for all stakeholders.
                  </p>
                  <ol>
                    <li>
                      <h5>Disciplined & systematic</h5>
                      <p>
                        We back credible companies with strong management teams
                        according to disciplined and systematic policies and
                        guidelines.
                      </p>
                    </li>
                    <li>
                      <h5>Deal creation</h5>
                      <p>
                        With our strong global network, we generate a healthy
                        pipeline of proprietary deals across a wide range of
                        industries and markets.
                      </p>
                    </li>
                    <li>
                      <h5>Alignment with management</h5>
                      <p>
                        We believe that high-quality management is critical to
                        any successful investment. Tennor does not interfere
                        with daily management activities but aims to align its
                        interest with the management both financially and
                        strategically
                      </p>
                    </li>
                    <li>
                      <h5>Superior returns</h5>
                      <p>
                        When determining an investment realisation strategy,
                        primary consideration is given to long-term profitable
                        growth of the company. Throughout the life of the
                        investment, Tennor seeks to enable various exit
                        opportunities through IPOs, block trades, private
                        placements, refinancing etc. in order to accommodate
                        diverse investment needs of stakeholders.
                      </p>
                    </li>
                    <li>
                      <h5>Value-added investing</h5>
                      <p>
                        We believe in the value of collaboration and seek to
                        unlock upside both strategically and operationally. Our
                        investment teams spend a significant amount of time
                        working with management teams of portfolio companies to
                        facilitate the implementation of effective operational
                        and marketing strategies. This being over and above the
                        financial support we provide these companies with.
                      </p>
                    </li>
                    <li>
                      <h5>Crypto For All</h5>
                      <p>
                        We’re building an inclusive financial system that makes
                        Crypto a universal option for billions of people
                        worldwide. Crupto is a resource for human progress, and
                        TIGER CREST CAPITAL INVESTMENT is the gateway.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
