import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./pages/about";
import Home from "./pages/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Contact from "./pages/contact";
import Expertise from "./pages/expertise";
import InvestmentPhilosophy from "./pages/investmentphilosophy";
import Teams from "./pages/team";
import BusinessPartners from "./pages/business_partners";
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/expertise" element={<Expertise />} />
        <Route
          path="/investmentphilosophy"
          element={<InvestmentPhilosophy />}
        />
        <Route
          path="/team"
          element={<Teams />}
        />
        <Route
          path="/business_partners"
          element={<BusinessPartners />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
