import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
export default function Navbar() {
  const location = useLocation();
  useEffect(() => {
    // This function will be called whenever the route changes
    // You can perform any logic here to update the class name
    updateClassName();
  }, [location.pathname]); // Dependency on location.pathname

  const updateClassName = () => {
    // Logic to update the class name based on the current route
    const navbar = document.getElementById('navbar'); // Replace 'yourNavbarId' with the actual ID of your navbar

    if (navbar) {
      // Check if the route condition is met, and update the class name accordingly
      const newClassName =
        (location.pathname === '/' || location.pathname === '/contact' || location.pathname === '/about' || location.pathname === '/expertise' || location.pathname === '/investmentphilosophy') 
          ? 'navbar-collapse collapse navbar-right navigation-holder'
          : 'navbar-collapse collapse navbar-right navigation-holder slideInn';

      // Update the class name of the navbar
      navbar.className = newClassName;
    }
  };
  return (
    <header id="header" className="site-header header-style-1">
    <div className="topbar">
      <div className=""  style={{width:"90%",margin:"auto"}}>
        <div className="row">
          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <div className="contact-info">
              <ul>
                <li>
                  <i className="fa fa-envelope" /> support@tigercrestcapital.com
                </li>
                <li>
                  <i className="fa fa-location-dot" /> 5th floor, office 506 Metropolis tower Business bay Dubai, UAE
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <div className="social-quote">
              <div className="social-links">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li>
                  <li style={{marginRight:15}}>
                    <a href="#">
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="quote">
                <Link to="/contact" style={{textDecoration:"none"}}>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </div>
    {/* end topbar */}
    <nav className="navigation navbar navbar-default">
      <div className="" style={{width:"90%",margin:"auto"}}>
        <div className="navbar-header">
          <button type="button" className="open-btn">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <Link className="navbar-brand" to="/">
            <img src="assets/images/logo.png" alt="" />
          </Link>
        </div>
        <div
          id="navbar"
          className="navbar-collapse collapse navbar-right navigation-holder"
        >
          <button className="close-navbar">
            <i className="fa fa-xmark" />
          </button>
          <ul className="nav navbar-nav">
            <li>
            <Link to="/" style={{ color: location.pathname === '/' ? 'gold' : 'inherit' }}>Home</Link >
            </li>
            <li>
              <Link to="/expertise" style={{ color: location.pathname === '/expertise' ? 'gold' : 'inherit' }}>Expertise</Link>
            </li>
            <li>
              <Link to="/investmentphilosophy" style={{ color: location.pathname === '/investmentphilosophy' ? 'gold' : 'inherit' }}>Investment Philosophy</Link>
            </li>
            <li>
              <Link to="/team" style={{ color: location.pathname === '/team' ? 'gold' : 'inherit' }}>Team</Link>
            </li>
            <li>
              <Link to="/business_partners" style={{ color: location.pathname === '/business_partners' ? 'gold' : 'inherit' }}>Business Partners</Link>
            </li>
            <li>
              <Link to="/about"  style={{ color: location.pathname === '/about' ? 'gold' : 'inherit' }}>About Us</Link>
            </li>
            <li>
              <Link to="/contact" style={{ color: location.pathname === '/contact' ? 'gold' : 'inherit' }}>Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* end of container */}
    </nav>
  </header>
  
  );
}
