import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Slide = ({ imgSrc, subtitle, title, btnLink, videoLink }) => (
  <div id="headerheight">
    <div
      className="slider-bg"
      style={{
        background: `url(${imgSrc})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col col-lg-8" id="headerslider">
            <div className="slide-title">
              <h2>{subtitle}</h2>
            </div>
            <div className="slide-title">
              <p style={{ color: "#000", fontSize: 18 }}>{title}</p>
            </div>
            <div className="btns">
              <Link href={btnLink} className="btn theme-btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const MainHeader = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slidesData = [
    {
      imgSrc: "assets/images/slider/slide-1.jpg",
      subtitle: "Where Capital Market Meets Cutting-Edge Technology",
      title:
        "We combine our partners’ resources with those of private sector investors to nurture early-stage technology startups as well as low-middle stage, giving the potential investee’s talent the opportunity to make an impact on the world.",
      btnLink: "/about",
      videoLink: "https://www.youtube.com/embed/7e90gBu4pas?autoplay=1",
    },
    {
      imgSrc: "assets/images/slider/slide-2.jpg",
      subtitle:
        "Focused Approach to Adding Value, with a Highly Experienced Team",
      title:
        "Our sector focus propels us to work with management teams to add value in a targeted mannerbased on their unique needs",
      btnLink: "/investmentphilosophy",
      videoLink: "https://www.youtube.com/embed/7e90gBu4pas?autoplay=1",
    },
    {
      imgSrc: "assets/images/slider/slide-3.jpg",
      subtitle: "Valuation and Discounted Cash Flow Modeling",
      title:
        "We use our in-depth sector knowledge to inform modeling scenarios, understand any gaps between market value and intrinsic value, and shape recommendations for action",
      btnLink: "/expertise",
      videoLink: "https://www.youtube.com/embed/7e90gBu4pas?autoplay=1",
    },
  ];

  return (
    <section className="hero hero-slider-wrapper hero-style-1">
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <Slide key={index} {...slide} />
        ))}
      </Slider>
    </section>
  );
};

export default MainHeader;
