export default function FeaturesSection1() {
  return (
    <section className="features-section-s2 section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="feature-grids">
              <div className="grid">
                <div className="icon">
                  <i className="fa fa-coins" style={{fontSize:32,color:"goldenrod"}} />
                </div>
                <h3>Lean finance</h3>
                <p>
                  We use lean principles and well-tested improvement levers,
                  such as consolidation and shared services, demand management,
                  and organizational streamlining, to optimize the practices and
                  performance of the finance function.
                </p>
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fa fa-check" style={{fontSize:32,color:"goldenrod"}}/>
                </div>
                <h3>Treasury optimization</h3>
                <p>
                  We draw upon benchmarks and extensive experience to streamline
                  treasury activities and transform the treasurer into a
                  strategic partner who drives value.
                </p>
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fa fa-money-check" style={{fontSize:32,color:"goldenrod"}} />
                </div>
                <h3>Capital Market Diagnostics (CMDs)</h3>
                <p>
                  We perform diagnostics on both companies and industries. CMDs
                  provide clients with a holistic perspective on past
                  performance and strategies for improving future performance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </section>
  );
}
