export default function Team() {
  return (
    <section className="team-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-4 col-md-7">
            <div className="section-title-s3">
              <span>Team</span>
              <h2>Our Expert Team</h2>
              <p>
              Focused Approach To Adding Value, With A Highly Experienced Team
              </p>
            </div>
          </div>
          <div className="col col-lg-8 col-xs-12">
            <div className="team-grids clearfix">
              <div className="grid">
                <div className="member-pic">
                  <img src="assets/images/team/img-3.jpg" alt="" />
                </div>
                <div className="details">
                  <h3>Jackey Choi</h3>
                  <p>Head of Investment / Partner </p>
                </div>
              </div>
              <div className="grid">
                <div className="member-pic">
                  <img src="assets/images/team/img-1.jpg" alt="" />
                </div>
                <div className="details">
                  <h3>Farhan</h3>
                  <p>Head of Investment / Partner </p>
                </div>
              </div>
              <div className="grid">
                <div className="member-pic">
                  <img src="assets/images/team/img-3.jpg" alt="" />
                </div>
                <div className="details">
                  <h3>Salim</h3>
                  <p>Head of Investment / Partner </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {/* end container */}
    </section>
  );
}
