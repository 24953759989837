import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="site-footer">
      <div className="upper-footer">
        <div className="container">
          <div className="row">
          <div className="col col-lg-4">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src="assets/images/footer-logo.png" alt="" />
                </div>
                <p>
                  Where Capital Market Meets Cutting-Edge Technology.Get
                  agenda-setting scoops on transformative deals from
                  authoritative sources across multiple industries.
                </p>
              </div>
            </div>
            <div className="col col-lg-2"></div>
            <div className="col col-lg-3">
              <div className="widget link-widget">
                <h3 style={{color:"#fff"}}>Quick Links</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/investmentphilosophy">Investment Philosophy</Link>
                  </li>
                  <li>
                    <Link to="/expertise">Expertise</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-3">
              <div className="widget link-widget">
                <h3 style={{color:"#fff"}}>Office</h3>
                <p>5th floor, office 506 Metropolis tower Business bay Dubai, UAE</p>
                <hr/>
                <p>+971 45770425</p>
                <p>support@tigercrestcapital.com</p>
              </div>
            </div>
          </div>
        </div>
        {/* end container */}
      </div>
      <div className="lower-footer">
        <div className="container">
          <div className="row">
            <div className="separator" />
            <div className="col col-xs-12">
              <p className="copyright">
                Copyright © 2024 Tiger Crest Capital. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
