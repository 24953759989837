import { useState } from "react";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
export default function ContactSection() {
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [subject,setSubject] = useState("");
  const [message,setMessage] = useState("");
  
  const SendMail = async (e) => {
    e.preventDefault();

    // Form data
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('message', message);

    try {
      // Make the API call
      const response = await fetch('https://tigercrestcapital.com/send_email.php', {
        method: 'POST',
        body: formData,
      });

      // Handle the response
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Message sent successfully!',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <section className="contact-page-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-5 col-sm-6">
            <div className="section-title-s3">
              <span>Contact with us</span>
              <h2>Request a Free Consultation</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-6 col-md-7">
            <div className="contact-form">
              <form
              onSubmit={SendMail}
              >
                <div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Your Name *"
                    onChange={(e)=>setName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Your Email *"
                    onChange={(e)=>setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className="form-control"
                    placeholder="Subject *"
                    onChange={(e)=>setSubject(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <textarea
                    className="form-control"
                    name="note"
                    id="note"
                    placeholder="Message..."
                    defaultValue={""}
                    onChange={(e)=>setMessage(e.target.value)}
                    required
                  />
                </div>
                <div className="submit-btn-wrapper">
                  <button type="submit" className="theme-btn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col col-lg-6 col-md-5">
            <div className="contact-info-area">
              <div className="title">
                <h3>Contact Info</h3>
                <p>Feel Free to Contact Us...</p>
              </div>
              <div className="contact-info">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fa fa-location-dot" />
                    </div>
                    <p>
                      5th floor, office 506 Metropolis tower Business bay Dubai, UAE
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <p>
                    Mr. jackey Choi - Head of Tiger Crest Capital <br/>
                    choi@tigercrestcapital.com<br/>
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <p>
                      Mr. Salim - Head of Tiger Crest Capital <br/>
                    salim@tigercrestcapital.com
                    </p>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <p>
                    Mr. Farhan - Head of Tiger Crest Capital <br/>
                    farhan@tigercrestcapital.com
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      {/* end container */}
    </section>
  );
}
