import AboutSection from "../components/AboutSection";
import AboutSection2 from "../components/AboutSection2";
import AboutSection3 from "../components/Team";
import CTASection from "../components/CTASection";
import ContactSection from "../components/ContactSection";
import FeaturesSection1 from "../components/FeaturesSection1";
import MainHeader from "../components/mainheader";
import CTASection2 from "../components/CTASection2";
import Partners from "../components/Partners";

export default function Home() {
  return (
    <>
      <MainHeader />
      <FeaturesSection1 />
      <AboutSection />
      <CTASection />
      <AboutSection2 />
      <AboutSection3 />
      <CTASection2 />
      <Partners />
      <div style={{ width: "90%", margin: "auto" }}>
        <h1 className="text-center">Our Location</h1>
        <p className="text-center">
          5th floor, office 506 Metropolis tower Business bay Dubai, UAE
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5424339514766!2d55.2769027!3d25.184923500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f68319169f881%3A0x69266793dd38e629!2sThe%20Metropolis%20Tower%20-%20Business%20Bay%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1710013891792!5m2!1sen!2s"
          style={{ border: 0, width: "100%",height:450 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <ContactSection />
    </>
  );
}
