export default function CTASection() {
  return (
    <section className="cta-section">
      <div className="container">
        <div className="row">
          <div className="col col-lg-8 col-sm-10">
            <div className="cta-text">
              <h3>
                Focused Approach to Adding Value, with a Highly Experienced Team
              </h3>
              <p>
                TIGER CREST CAPITAL INVESTMENT is independent and solely focused
                on maximizing shareholder value. We are thus positioned to be an
                objective, high quality partner to management teams and owners
                in making major strategic decisions
              </p>
              <ul>
                <li>
                  <i className="fa fa-phone" style={{ color: "#fff" }} />{" "}
                  +971508354188
                </li>
                <li>
                  <i className="fa fa-envelope" style={{ color: "#fff" }} />{" "}
                  support@tigercrestcapital.com
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </section>
  );
}
