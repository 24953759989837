import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <h2>About Us</h2>
              <ol className="breadcrumb">
                <li>
                  <Link to="/" style={{ color: "#fff" }}>
                    Home
                  </Link>
                </li>
                <li>About Us</li>
              </ol>
            </div>
          </div>{" "}
          {/* end row */}
        </div>{" "}
        {/* end container */}
      </section>

      <section className="case-single-section section-padding">
        <div className="container">
          <div className="row content">
            <div className="col col-xs-12">
              <div className="overview-info">
                <div className="overview">
                  <h2>About us</h2>
                  <p>
                    From generating deal flow to analyzing private equity fund
                    and venture capital performance, TIGER CREST CAPITAL
                    INVESTMENT LLC.(Herein after “TIGER CREST CAPITAL
                    INVESTMENT”) provides the comprehensive information on this
                    market you need
                  </p>
                  <p>
                    We combine our partners’ resources with those of private
                    sector investors to nurture early-stage technology startups
                    as well as low-middle stage, giving the potential investee’s
                    talent the opportunity to make an impact on the world. We
                    grow our ecosystem by encouraging more private funding or
                    investment from angel investors and venture capitalists.
                    Grow TIGER CREST CAPITAL INVESTMENT ’s ecosystem with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row content">
            <div className="col col-md-6">
              <div className="challange">
                <h3>What makes us different?</h3>
                <p>
                  TIGER CREST CAPITAL INVESTMENT has six characteristics that
                  differentiates it from other investment firms:
                </p>
                <ul>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Paving the way for achieving DIGITAL programme
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Simplify alternative investing
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Diversify your portfolio
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Trade your positions
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Wide access to capital
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Bespoke financing and investment solutions
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-md-6">
              <div className="challange">
                <h3>Digital Trasnforming</h3>
                <p>
                  Pride ourselves on the commitment we show to our investors.
                  Our credibility lies within our investment methodology, built
                  on four key pillars under which we operate.
                </p>
                <ul>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Transparency
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Safty
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Excellence
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Innovation
                  </li>
                  <li>
                    <i className="fa fa-arrow-right" />
                    Operational expertise
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row content">
            <div className="col col-md-12">
              <div className="challange">
                <h3>What Makes Us Different?</h3>
                <ol>
                  <li>
                    <h5>Paving the way for achieving DIGITAL programme.</h5>
                    <p>
                      Digital technology and infrastructure have a critical role
                      in our private lives and business environments. At the
                      same time, the COVID-19 pandemic highlighted not only how
                      much we rely on our technology to be available to us, but
                      also how important it is not to be dependent on systems
                      and solutions coming from other regions of the world.
                    </p>
                    <p>
                      The Digital TIGER CREST CAPITAL INVESTMENT Programme
                      (DIGITAL) is a new TIGER CREST CAPITAL INVESTMENT funding
                      programme focused on bringing digital technology to
                      businesses, citizens and public administrations
                    </p>
                  </li>
                  <li>
                    <h5>Simplify alternative investing</h5>
                    <p>
                      Our digitalized end-to-end marketplace brings issuers and
                      investors together in one platform, allowing our investors
                      a simpler experience building and managing their alts
                      portfolio.
                    </p>
                  </li>
                  <li>
                    <h5>Diversify your portfolio</h5>
                    <p>
                      Diversify across asset classes and cut down on research
                      time by accessing unique investment offerings on one
                      platform
                    </p>
                  </li>
                  <li>
                    <h5>Trade your positions</h5>
                    <p>
                      Unlock your net worth with the ability to trade select
                      holdings through our efficient and secure peer-to-peer
                      trading platform working with and backing entrepreneurs
                      which has allowed it to build a differentiated network
                      from where it sources unique opportunities.
                    </p>
                  </li>
                  <li>
                    <h5>Wide access to capital</h5>
                    <p>
                      This includes private pools, sovereign and institutional
                      investors who have aligned interest in long-term value
                      creation.
                    </p>
                  </li>
                  <li>
                    <h5>Bespoke financing and investment solutions</h5>
                    <p>
                      Deep experience and knowledge across the firm allows it to
                      execute optimised financing structures for each
                      transaction.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="row content">
            <div className="col col-md-12">
              <div className="challange">
                <h3>What we do?</h3>
                <ol>
                  <li>
                    <h5>Transparency</h5>
                    <p>
                      We believe that trust and transparency are paramount and
                      cultivate relationships with investors and partners based
                      on mutual trust and complete openness.
                    </p>
                  </li>
                  <li>
                    <h5>Safty</h5>
                    <p>
                      We commit to the highest standards of risk-management in
                      all investments. We use modern tools to ensure we are
                      operating in a safe and sustainable manner.
                    </p>
                  </li>
                  <li>
                    <h5>Excellence</h5>
                    <p>
                      We pride ourselves on the level of excellence provided in
                      our financial performance, customer service, and
                      organizational efficiency
                    </p>
                  </li>
                  <li>
                    <h5>Innovation</h5>
                    <p>
                      We believe in providing our clients with products that
                      deliver superior performance and access to forward-looking
                      investments, in line with their long-term objectives.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="row content">
            <div className="col col-md-12">
              <div className="challange">
                <h3>Operational expertise</h3>
                <p>
                  Ability to recruit and retain top industry teams, both
                  in-house and at portfolio company level, allows investment
                  opportunities to prosper TIGER CREST CAPITAL INVESTMENT
                  provides unique professional and personal development
                  opportunities in one of the world’s most dynamic markets. As
                  the country’s and region’s economies grow, we will help
                  companies at all stages of maturity, from start-up to
                  rejuvenation and consolidation. Joining TIGER CREST CAPITAL
                  INVESTMENT ’s clients also means working alongside senior
                  thought leaders, and contributing to meaningful industry or
                  economic research. We will be able to support, grow
                  intellectually, and have real impact on clients and grow our
                  presence.
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end container */}
      </section>
    </>
  );
}
